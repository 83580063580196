@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

:root {
  --primary: #40496b;
  --secondary: #e26e4b;
}

body {
  padding: 0px !important;
  margin: 0px !important;
}

body * {
  font-family: "Karla", sans-serif;
}

.margin-auto {
  margin: auto;
}

.max-width {
  max-width: calc(500px - 80px);

}

.border {
  border: 1px solid #40496b50;
  border-radius: 5px;
}

.help {
  width: fit-content;
  padding: 10px;
  position: fixed;
  bottom: 20px;
  right: 40px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .help {
    display: none;
  }

  .max-width {
    width: calc(90% - 40px) !important;
  }
}